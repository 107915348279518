import { firiMerge } from '@firi/shared/styles';

export const PercentChange = (props: { value: number | string; setPrefix?: boolean }) => {
  const value = Number(props.value);
  return (
    <div className={firiMerge(value < 0 ? 'text-red-900' : 'text-green-900')}>
      {props.setPrefix && <>{value >= 0 ? '+' : '-'}</>} {String(Math.abs(value).toFixed(2))}%
    </div>
  );
};
