import { isEmptyArray } from '@firi/shared';
import { PnlPeriod } from '@firi/shared/gql/gqlTypes';
import { Button, Card, Chart, Icon } from '@firi/ui-web';
import { Trans } from '@lingui/macro';
import { Currency } from 'components/Currency';
import { PercentChange } from 'components/PercentChange';
import { PeriodButtons } from 'components/PeriodButtons';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { getLocalizedUrl } from 'helpers/url';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CryptocurrencyItemsData } from 'pages/api/cryptocurrencyItems';
import { useState } from 'react';
import useSWR, { Fetcher } from 'swr';

const fetcher: Fetcher<CryptocurrencyItemsData, string> = (url) =>
  fetch(url).then((res) => res.json());

export const List = ({
  markets,
  isLoading,
}: {
  markets: CryptocurrencyItemsData | undefined;
  isLoading?: boolean;
}) => {
  const router = useRouter();

  return (
    <Card>
      <div className="divide-y divide-black-100">
        {!isLoading
          ? markets?.map((market) => {
              const dataPoints =
                market.priceHistory?.data.map((point) => ({
                  price: Number(point?.price || 0),
                  date: format(new Date(point?.date || ''), 'MMM dd'),
                })) || [];

              const coinArticleUrl = getLocalizedUrl(`/cryptocurrency/[coin]`, router.locale, {
                coin: market.slug?.current,
              });
              return (
                <div key={market.symbol}>
                  <Link href={coinArticleUrl} locale={router.locale}>
                    <a className="justify-between items-center grid gap-3 grid-cols-8 lg:grid-cols-12">
                      <div className="py-4 flex items-center col-span-3 lg:col-span-4">
                        <div>
                          <Icon icon={market.baseCurrency?.symbol} size="lg" />
                        </div>
                        <div className="flex items-baseline">
                          <Typography className="ml-4 mr-2" color="blue">
                            {market.baseCurrency?.name}
                          </Typography>
                          <Typography className="hidden md:inline-block" size="xs" color="2">
                            {market.baseCurrency?.symbol}
                          </Typography>
                        </div>
                      </div>
                      <div className="space-x-2 text-right col-span-3 lg:col-span-2">
                        <Typography size="sm">
                          <Currency value={market.lastPrice || ''} />
                        </Typography>
                        <Typography size="xs">{market.quoteCurrency?.symbol}</Typography>
                      </div>
                      <div className="col-span-2 text-right">
                        <Typography size="sm">
                          {market.change && <PercentChange value={market.change} setPrefix />}
                        </Typography>
                      </div>

                      <div className="hidden  lg:block h-full w-full max-w-[120px] ml-auto col-span-2">
                        <Chart
                          dataPoints={dataPoints}
                          showVerticalLines={false}
                          showHorizontalLines={false}
                          showTooltip={false}
                          showXAxis={false}
                          showYAxis={false}
                          lineType="linear"
                          stroke={Number(market.change) < 0 ? '#E8667E' : '#1E856C'}
                        />
                      </div>
                      <div className="hidden lg:flex col-span-2 justify-end">
                        <Button size="sm" onClick={() => router.push(coinArticleUrl)}>
                          <Trans>See more</Trans>
                        </Button>
                      </div>
                    </a>
                  </Link>
                </div>
              );
            })
          : [...Array(4)].map((_, i) => (
              <div
                key={i}
                className="py-4 justify-between items-center grid gap-3 grid-cols-8 lg:grid-cols-12"
              >
                <div className="flex items-center col-span-3 lg:col-span-4">
                  <div className="rounded-full w-6 h-6 bg-black-200"></div>
                  <div>
                    <div className="w-20 h-6 bg-black-200 ml-4 mr-2"></div>
                  </div>
                </div>
                <div className="space-x-2 text-right col-span-3 lg:col-span-2">
                  <div className="w-full h-6 bg-black-200"></div>
                </div>
                <div className="col-span-2 text-right">
                  <div className="w-full h-6 bg-black-200"></div>
                </div>
                <div className="hidden lg:flex justify-end col-span-2">
                  <div className="w-full max-w-[120px]">
                    <div className="w-full h-6 bg-black-200"></div>
                  </div>
                </div>
                <div className="hidden lg:flex col-span-2 justify-end">
                  <div className="w-10 rounded-lg h-6 bg-black-200"></div>
                </div>
              </div>
            ))}
      </div>
    </Card>
  );
};

export const CryptoCurrencyList = () => {
  const [period, setPeriod] = useState<PnlPeriod>(PnlPeriod.Week);
  const router = useRouter();
  const clientCountry = router.locale === 'dk' ? 'DK' : 'NO';

  const { data, error } = useSWR(
    `/api/cryptocurrencyItems?period=${period}&country=${clientCountry}`,
    fetcher,
  );

  const isLoading = !data && isEmptyArray(data || []);

  if (error) return null;

  return (
    <div>
      <div className="mb-6">
        <PeriodButtons selectedPeriod={period} onSelect={setPeriod} />
      </div>

      <List markets={data} isLoading={isLoading} />
    </div>
  );
};
