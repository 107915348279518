import { PnlPeriod } from '@firi/shared/gql/gqlTypes';
import { Button } from '@firi/ui-web';
import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import React from 'react';

import { Typography } from './Typography';

const Periods: Record<PnlPeriod, string> = {
  [PnlPeriod.Day]: t`24H`,
  [PnlPeriod.Week]: t`7D`,
  [PnlPeriod.Month]: t`1M`,
  [PnlPeriod.ThreeMonth]: t`3M`,
  [PnlPeriod.Year]: t`1Y`,
  [PnlPeriod.All]: t`From start`,
};

interface PeriodButtonsProps {
  selectedPeriod: PnlPeriod;
  onSelect: (p: PnlPeriod) => void;
}

export const PeriodButtons = ({ selectedPeriod, onSelect }: PeriodButtonsProps) => (
  <div className="flex items-center">
    {Object.keys(Periods).map((p) =>
      selectedPeriod == p ? (
        <Button size="sm" skin="tertiary" key={p}>
          <Trans id={Periods[p as PnlPeriod]} />
        </Button>
      ) : (
        <button onClick={() => onSelect(p as PnlPeriod)} className="px-2" key={p}>
          <Typography size="xs">
            <Trans id={Periods[p as PnlPeriod]} />
          </Typography>
        </button>
      ),
    )}
  </div>
);
